<!-- 退件单 -->
<template>
    <div class="asnBill">
        <div ref="commonSearchRef">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <!-- <el-tab-pane label="全部" name="100"></el-tab-pane> -->
                <el-tab-pane v-for="(item, index) in returnStates" :key="index"
                :label="item.label" :name="item.value">
                <template #label>
                    {{getTabs(item)}}
                </template>
                </el-tab-pane>
            </el-tabs>
            <div class="publicSearch p-10">
                <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
                    <div>
                        <el-input style="width: 300px;" v-model="searchData.keyWord" size="mini" placeholder="退件单号搜索，支持输入空格批量搜索" clearable></el-input>
                    </div>
                    <div>
                        <el-select v-model="searchData.billSource" size="mini" filterable placeholder="退件订单来源" clearable>
                            <el-option v-for="(item, index) in billSourceStates" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div v-if="activeName === '3' || activeName === '4'">
                        <el-select v-model="searchData.createUserName" size="mini" filterable placeholder="清点人" clearable>
                            <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userName"></el-option>
                        </el-select>
                        <el-select v-model="searchData.isBool" size="mini" filterable placeholder="是否缺货" clearable class="ml10">
                        <el-option v-for="(item, index) in isBoolList" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div>
                    <el-select v-model="searchData.timeType" size="mini" @change="handleDateKeyChange">
                        <el-option v-for="it in getDateList" :label="it.label" :value="it.value" :key="it.value"/>
                    </el-select>
                    <el-date-picker
                        v-model="searchData.times"
                        type="daterange" size="mini"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    </div>
                    <div>
                        <el-button @click="getList" size="mini" icon="el-icon-search" type="primary">查询</el-button>
                        <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
                    </div>
                </div>
                <!-- <span class="moreSearchBtn" :class="isMoreSearch ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click="isMoreSearch = !isMoreSearch"></span> -->
            </div>
            <div class="headerBtns">
                <el-button size="small" type="primary" @click="groundBtn(false)" :disabled="!multipleSelection.length" v-if="activeName == 3"> 批量上架 </el-button>
                <el-button class="ml10" size="mini" type="primary" plain icon="iconfont icon-7" @click="exportReport()">导出</el-button>
            </div>
        </div>
        <div class="tableBox">
            <el-table
                ref="commonTableRef"
                tooltip-effect="dark myTooltips"
                :row-style="$parent.tableRowStyle"
                highlight-current-row
                stripe
                border
                :header-cell-style="$parent.tableHeaderColor"
                v-loading="loading.masterTable"
                :data="asnBills"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                row-key="billNo"
                :expand-row-keys="expandRowKeys"
                :height="dynamicsHeight(commonSearchRef, true, true, 10)"
                @expand-change="(a,b) => handleExpandChange(a, b, 'item')"
            >
                <el-table-column type="selection" width="55" key="0" fixed="left" v-if="activeName == 3"></el-table-column>
                <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
                <el-table-column type="expand" key="1">
                    <template slot-scope="scope" v-if="scope.row.returnDetailList">
                        <div class="p-10">
                            <el-table :data="scope.row.returnDetailList" border style="width: 100%">
                                <el-table-column prop="imageUrl" :label="$t(`m.common.picture`)" key="1">
                                    <template slot-scope="scope">
                                        <el-image style="width: 63px; height: 63px; " :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]"></el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemCode" label="SKU" key="2"> </el-table-column>
                                <el-table-column prop="itemName" :label="$t(`m.common.product_name`)" key="3"> </el-table-column>
                                <el-table-column prop="returnQuantity" label="退货量" key="4"> </el-table-column>
                                <el-table-column prop="" label="良品 / 次品 / 报废品" v-if="activeName==3||activeName==4" key="5">
                                    <template slot-scope="scope">
                                        {{ scope.row.quantity||0 }} / {{ scope.row.rejectQuantity||0 }} / {{ scope.row.scrapQuantity||0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="qhNum" label="缺货数量" v-if="activeName==3||activeName==4" key="6"></el-table-column>
                            </el-table>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="returnNo" label="退件单号" width="200" key="2">
                    <template slot-scope="scope">
                        <span class="textBtn" @click="toDetail(scope.row)">退件单：{{ scope.row.returnNo }}</span>
                        <br>
                        <span>关联单：{{ scope.row.billNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="wlxx" label="物流信息" key="3" width="200">
                    <template slot-scope="scope">
                        <span>物流商：{{ scope.row.supplierName }}  </span>
                        <br>
                        <span>物流跟踪号：{{ scope.row.trackingNumber || '-' }} </span>
                    </template>
                </el-table-column>
                <el-table-column key="4" show-overflow-tooltip prop="rukxx" label="退件订单来源">
                    <template slot-scope="scope">
                        <span> {{ formatListDataByKey(scope.row.billSource, false, billSourceStates, 'label', 'value' ) }} </span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="skuSize" label="SKU种类" width="100" key="5"></el-table-column>
                <el-table-column show-overflow-tooltip prop="returnCount" label="总退货数" width="100" key="6"></el-table-column>
                <el-table-column show-overflow-tooltip prop="" label="已收数量" width="100" v-if="activeName==3||activeName==4" key="7">
                    <template slot-scope="scope">
                        {{  getQuantity('quantity', scope.row.returnDetailList) +
                            getQuantity('rejectQuantity', scope.row.returnDetailList) +
                            getQuantity('scrapQuantity', scope.row.returnDetailList) }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="" label="良品/次品/报废品" width="120" v-if="activeName==3||activeName==4" key="8">
                    <template slot-scope="scope">
                        {{ getQuantity('quantity', scope.row.returnDetailList) }} /
                        {{ getQuantity('rejectQuantity', scope.row.returnDetailList) }} /
                        {{ getQuantity('scrapQuantity', scope.row.returnDetailList) }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="createUserName" label="清点人" width="100" v-if="activeName==3||activeName==4" key="9"> </el-table-column>
                <el-table-column show-overflow-tooltip prop="qhNum" label="缺货数量" width="100" v-if="activeName==4" key="14">
                    <template slot-scope="scope">
                        {{ getQuantity('qhNum', scope.row.returnDetailList) }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="cellCode" label="暂存库位" width="100" v-if="activeName==2" key="11"></el-table-column>
                <el-table-column show-overflow-tooltip prop="returnUdfHs1" :label="$t(`m.common.remark`)" min-width="100" key="12"> </el-table-column>
                <el-table-column show-overflow-tooltip prop="wlxx" label="相关时间" width="260" key="13">
                    <template slot-scope="scope">
                        <div>创建时间：<span> {{ scope.row.createTime||'-' }} </span></div>
                        <div v-if="activeName==2||activeName==3||activeName==4">到仓时间：<span> {{ scope.row.reachTime||'-' }} </span></div>
                        <div v-if="activeName==3||activeName==4">清点时间：<span> {{ scope.row.checkTime||'-' }} </span></div>
                        <div v-if="activeName==4">完成时间：<span> {{ scope.row.endTime||'-' }} </span></div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t(`m.common.operation`)" fixed="right" min-width="100" key="17">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toDetail(scope.row)">详情</el-button>
                        <el-button type="text" @click="goToFastAcceptBtn(scope.row)" v-if="scope.row.state == 1">到仓</el-button>
                        <el-button type="text" @click="goToFastAcceptBtn(scope.row)" v-if="scope.row.state == 2">清点</el-button>
                        <el-button type="text" v-if="scope.row.state == 3" @click="groundBtn(scope.row)">上架</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                class="pagination-bottom"
                style="text-align: right"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="searchData.pageNum"
                :page-sizes="[5, 10, 15, 20, 25]"
                :page-size="searchData.pageSize"
                layout="sizes, total, prev, pager, next"
                :total="searchData.total"
            ></el-pagination>
        </div>
        <el-dialog title="备注" :visible.sync="memoVisible" width="30%">
            <el-form :model="rowData">
                <el-form-item label="订单号" :label-width="formLabelWidth">
                    <span>{{ memoData.billNo }}</span>
                </el-form-item>
                <el-form-item :label="$t(`m.common.remark`)" :label-width="formLabelWidth">
                    <el-input style="width:350px" type="textarea" rows="4" maxlength="200" show-word-limit v-model="memoData.memo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="memoVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitMemo">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import { formatListDataByKey, dynamicsHeight } from '@/utils/DateUtils.js'
import {parseTime} from "@/utils/zhihui";

export default {
    name: 'ReturnBill',
    components: { },
    data() {
        return {
            commonSearchRef: null,
            searchRadio: '',
            queryForm: {},
            clientHeight: $(window).height(),
            noShipVisible: false,
            noShipInfo: {},
            memoData: {},
            memoVisible: false,
            asnPrintCode: 10,
            wlVisible: true,
            isMoreSearch: false,
            wareId: PF.getLocal('wareId'),
            loading: {
                masterTable: true,
            },
            rowData: {},
            carrierCode: null,
            searchData: {
                pageNum: 1,
                pageSize: 15,
                total: null,
                wareId: PF.getLocal('wareId'),
                keyWord: '',
                billSource: '',
                state: '1',
                createUserName: null,
                timeType: '1'
            },
            asnBills: [],
            platforms: [],
            carriers: [],
            formLabelWidth: '120px',
            asnUdfHs: [],
            organizations: [],
            suppliers: [],
            multipleSelection: [],
            asnTypes: [],
            billSourceOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '采购单',
                },
                {
                    value: '1',
                    label: '新建',
                },
                {
                    value: '2',
                    label: 'ERP导入',
                },
                {
                    value: '4',
                    label: '调拨',
                },
            ],
            activeName: '1',
            expandRowKeys: [],
            userList: [],
            isBoolList: [{label: '是', value: true}, {label: '否', value: false}],
            dateList: [
                {label: '创建时间', value: '1', classify: ['1', '2', '3', '4']},
                {label: '到仓时间', value: '2', classify: ['1', '2']},
                {label: '清点时间', value: '3', classify: ['1', '2', '3']},
                {label: '完成时间', value: '4', classify: ['1', '2', '3', '4']},
            ],
            tabObj: {}
        };
    },
    watch: {
        carrierCode(newVal, oldVal) {
            this.searchData.carrierCode = newVal;
            this.getList();
        },
    },
    computed: {
        dynamicsHeight,
        ...mapState(['reviewStates', 'asnStates', 'asnStatusTypes', 'qcStates', 'returnStates', 'billSourceStates']),
        getQuantity() {
            return (item, detailList) => {
                let num = _.reduce(detailList, (a, b) => _.toNumber(a||0) + _.toNumber(b[item]||0), 0)
                return num || 0
            }
        },
        getDateList() {
          const activeName = this.activeName
          const dateList = this.dateList
          return _.filter(dateList, it => _.includes(it.classify, activeName))
        },
        getTabs() {
          return (item = {}) => {
            const {count1, count2, count3} = this.tabObj
            let label = item.label
            if(item.value == 1) {
              label = label + ((count1 || count1 == 0) ? `(${count1 <= 999 ? count1 : '999+'})` : '')
            } else if(item.value == 2) {
              label = label + ((count2 || count2 == 0) ? `(${count2 <= 999 ? count2 : '999+'})` : '')
            } else if(item.value == 3){
              label = label + ((count3 || count3 == 0) ? `(${count3 <= 999 ? count3 : '999+'})` : '')
            }

            return label
          }
        }
    },
    methods: {
        formatListDataByKey,
        groundBtn(row) {
            let multipleSelection = _.map(this.multipleSelection||[], item => item.returnNo).join(',')
            this.$router.push({
                name: 'returnGround',
                params: row ? row : { returnNo: multipleSelection },
            })

        },
        // 批量收货
        goToFastAcceptBtn(row) {
            this.$router.push({ name: 'fastAccept', params: {...row, pageType: 'ReturnBill'}})
        },
        // 查询列表所有的数据
        getList() {
            var that = this;
            let {billSource, createUserName, keyWord} = PF.JSON(this.searchData);
            keyWord = keyWord ? keyWord.split(/,|，|\s+/).join(',') : null
            billSource = billSource ? billSource : null
            createUserName = createUserName ? createUserName : null
            this.expandRowKeys = []

            // 处理时间
            const {times} = this.searchData
            let startTime, endTime
            if(times && times.length > 0) {
                startTime = parseTime(times[0], '{y}-{m}-{d}') + ' 00:00:00'
                endTime = parseTime(times[1], '{y}-{m}-{d}') + ' 23:59:59'
            }
            that.loading.masterTable = true;
            IOT.getServerData('/return/masters/list', 'post', {...this.searchData, keyWord, billSource, createUserName, startTime, endTime}, ret => {
                that.loading.masterTable = false;
                this.asnBills = _.map(ret.list, i => {
                    i.returnDetailList = _.map(i.returnDetailList, ii => {
                        ii.qhNum = Math.abs(ii.returnQuantity - ((ii.quantity||0)+(ii.rejectQuantity||0)+(ii.scrapQuantity||0)))
                        return ii
                    })
                    return i
                }) ;
                this.searchData.total = ret.total;
                console.log(ret.total, 'ret.total',ret);
                this.searchData.pageNum = ret.pageNum;
            }, true);
            that.$nextTick(() => {
                that.$refs.commonTableRef.doLayout(); // 解决表格错位
            });
            // 获取tab 待入库 收获中 已收货总数
            IOT.getServerData('/asn/masters/findCount', 'post', {type:2, wareCode: PF.getLocal('wareCode')}, ret => {
                if (ret.code === 200) {
                this.tabObj = ret.data || {}
                } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            }, true);
        },
        // 导出
        exportReport(){
            let {billSource, createUserName, keyWord} = PF.JSON(this.searchData);
            keyWord = keyWord ? keyWord.split(/,|，|\s+/).join(',') : null
            billSource = billSource ? billSource : null
            createUserName = createUserName ? createUserName : null
            this.expandRowKeys = []

            // 处理时间
            const {times} = this.searchData
            let startTime, endTime
            if(times && times.length > 0) {
                startTime = parseTime(times[0], '{y}-{m}-{d}') + ' 00:00:00'
                endTime = parseTime(times[1], '{y}-{m}-{d}') + ' 23:59:59'
            }
            IOT.exportHandle('/return/masters/importCell', {...this.searchData, keyWord, billSource, createUserName, startTime, endTime}, (res) => {
            if (res.status === 200) {
                const temp = res.headers["content-disposition"].split(";")[1].split("filename=")[1];
                const fileName = decodeURIComponent(temp)
                const a = document.createElement("a");
                a.href = URL.createObjectURL(new Blob([res.data]));
                a.download = fileName
                document.body.appendChild(a);
                a.click();
            }
            });
        },
        // 详情
        toDetail(row) {
            this.$router.push({
                name: 'returnDetal',
                params: row,
            })
        },
        // 收货
        asnBillSub(row) {
            this.$router.push({
                name: 'fastAccept',
                params: row,
            })
        },
        // 单独点击展开触发
        handleExpandChange(row, expandList, type = 'item') {
            const obj = _.find(expandList, item => item.billNo === row.billNo)
            this.expandRowKeys = _.map(expandList || [], (it) => it.billNo);
            if (expandList.length > 0 && ((type === 'item' && obj) || type === 'all')) {
                let url = (url = '/asn/details/list');
                IOT.getServerData(url, 'post', { billNo: row.billNo }, ret => {
                    if (ret.code === 200) {
                        let list = ret.rows;
                        let deepAsnBills = _.cloneDeep(this.asnBills)
                        this.asnBills = _.map(deepAsnBills, i => {
                            if (i.billNo == row.billNo) {
                                i.details = list
                            }
                            return i
                        })
                    }
                });
            }
        },
        handleClick(tab, event) {
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                total: null,
                wareId: PF.getLocal('wareId'),
                keyWord: '',
                billSource: '',
            }
            this.activeName = tab.name
            this.searchData.state = tab.name
            this.searchData.timeType = '1'
            this.getList()
        },
        pringAsnCode() {
            window.IOT.getServerData(
                '/asn/codes/print',
                'get',
                { wareId: this.wareId, quantity: this.asnPrintCode },
                ret => {
                    if (ret.code === 200) {
                        var strHTML = ret.data;
                        this.$parent.printHtml(strHTML, null);
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );
        },
        isDelete(asnMasterId) {
            this.$confirm('确认关闭吗？（不会删除）')
                .then(() => {
                    this.deleteOrder(asnMasterId);
                })
                .catch(() => {});
        },
        deleteOrder(asnMasterId) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/masters/state6', 'get', { id: asnMasterId }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        isDeleteDetail(id) {
            this.$confirm('确认关闭吗？ ')
                .then(() => {
                    this.deleteAsnDetail(id);
                })
                .catch(() => {});
        },
        deleteAsnDetail(id) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/delete', 'get', { id: id }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        allotAsn() {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/allotAsn', 'get', {}, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        noShipCommit() {
            var noShipInfo = this.noShipInfo;

            window.IOT.showOverlay('提交中～～～');
            IOT.getServerData('/no/ships/add', 'post', noShipInfo, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('提交成功！', 'success');
                    this.noShipVisible = false;
                    this.noShipInfo = {};
                } else {
                }
            });
        },
        getAsnDetailData(billNo) {
            let url = (url = '/asn/details/list');
            IOT.getServerData(url, 'post', { billNo: billNo }, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;
                    this.asnBills.forEach(order => {
                        if (order.billNo == billNo) {
                            order.details = list;
                        }
                    });
                    var asnBills = JSON.parse(JSON.stringify(this.asnBills));
                    this.asnBills = [];
                    this.asnBills = asnBills;
                } else {
                }
            });
        },
        commitMemo() {
            var that = this;
            that.memoVisible = false;
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/memo',
                'get',
                {
                    asnMasterId: that.memoData.asnMasterId,
                    memo: that.memoData.memo,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },

        getWlgj(row) {
            window.IOT.getServerData(
                '/kuaidiniaos/jscx',
                'get',
                {
                    expCode: row.carrierCode,
                    expNo: row.shipBillCode,
                },
                ret => {
                    debugger;
                    window.IOT.hideOverlay();
                },
                true
            );
        },
        updateShip(asn) {
            var that = this;
            that.rowData = asn;
            that.shipFormVisible = true;
        },
        commitShip() {
            var that = this;

            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/addShipBillCode',
                'post',
                {
                    billNo: that.rowData.billNo,
                    asnMasterId: that.rowData.asnMasterId,
                    shipBillNo: that.rowData.shipBillCode,
                    shipCode: that.rowData.carrierCode,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },
        // isDelete(asnMasterId) {
        //             this.$confirm('确认删除吗？')
        //                 .then(() => {
        //                     this.deleteOrder(asnMasterId)
        //                 })
        //                 .catch(() => {});
        //         },
        //       deleteOrder(asnMasterId) {
        //           window.IOT.showOverlay('提交中～～～')
        //           window.IOT.getServerData('/asn/masters/delete', 'get', {id: asnMasterId}, (ret) => {
        //               window.IOT.hideOverlay()
        //               if (ret.code === 200) {
        //                   window.IOT.tips('删除成功！', 'success')
        //                   this.getList();
        //               } else {
        //                   window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
        //               }
        //           })
        //       },
        handleSizeChange(val) {
            this.searchData.pageSize = val;
            this.getList();
        },

        handleCurrentChange(val) {
            this.searchData.pageNum = val;
            this.getList();
        },
        clearSearchData() {
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                total: null,
                wareId: PF.getLocal('wareId'),
                keyWord: '',
                billSource: '',
                createUserName: null,
                state: this.activeName,
                timeType: '1'
            }
            this.getList()
        },
        getPlatformData() {
            IOT.getServerData('/platforms/findByWareId', 'get', { wareId: PF.getLocal('wareId', 'number') }, ret => {
                if (ret.code === 200) {
                    this.platforms = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCarrierData() {
            IOT.getServerData('/carriers/allList', 'get', {}, ret => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        commitFile() {
            IOT.showOverlay('保存中，请稍等...');
            let list = PF.JSON(this.multipleSelection);
            let billNos = [];
            for (let i = 0; i < list.length; i++) {
                billNos.push(list[i].billNo);
            }
            IOT.getServerData('/asn/masters/file', 'post', { billNos: billNos }, ret => {
                IOT.hideOverlay();
                this.multipleSelection = [];
                if (ret.code === 200) {
                    IOT.tips('归档成功！', 'success');
                    this.getList();
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCode() {
            var that = this;
            document.onkeypress = function(e) {
                that.getList();
            };
        },
      // 处理搜索条件切换时间类型清空值
      handleDateKeyChange() {
          this.searchData.times = undefined
      }
    },
    created() {
        //   this.getCode();
    },
    mounted() {
        this.commonSearchRef = this.$refs.commonSearchRef
        this.getList();
        this.getPlatformData();
        this.getCarrierData();
        PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
            this.asnUdfHs = udfs;
        });
        PF.getOrganizations(rows => {
            this.organizations = rows;
        });
        PF.getSuppliers(rows => {
            this.suppliers = rows;
        });
        PF.getDataDictUdfData('asnType', rows => {
            this.asnTypes = rows;
        });
        PF.getCarriers(rows => {
            this.carriers = rows;
        });
        PF.getUsersLists(rows => {
            this.userList = rows;
        });
        // console.log(process.env.VUE_APP_BASE_URL);
        // console.log(formatListDataByKey('2', false, this.asnStates, 'label', 'value'), 'pppp');
        // PF.getDataDictUdfData('saleType', (rows) => {
        //     console.log(rows, '字典');
        // });
    },
    activated() {
        this.getList();
    }
};
</script>

<style scoped lang="less">
.asnBill {
}
</style>
